.guide {
  background-color: #F5F5F5;

  &__body {
    padding: 15px 15px 40px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5)
  }

  &__img-wrapper {
    display: flex;
    justify-content: center;
    margin: 40px 0 80px;
  }

  &__img-item {
    // height: 770px;
    width: 85%;

    &.img-1 {
      width: 313px;
      background: url('./img/guide1.png') no-repeat;
      background-size: 313px 740px;
      margin-right: 30px;
    }

    &.img-2 {
      width: 956px;
      background: url('./img/guide2.png') no-repeat;
      background-size: 956px 740px;
    }
  }
}

.mobile {
  .guide__body {
    padding: 20px 20px 40px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5)
  }

  .guide__img-wrapper {
    flex-direction: column;
  }

  .guide__img-item {
    width: 100%;
    height: auto;
    &.img-2 {
      height: 310px;
      width: unset;
      background: url('./img/guide2.png') no-repeat;
      background-size: contain;
    }
  }
}
