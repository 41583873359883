@import '../../../styles/global/variables.scss';

.header-banner-widget {
  height: 224px;
  width: 955px;
  background-image: url('../../../../public/images/howToPlayPage/bg-shadow.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-color: $secondary-bg;
  border-radius: 8px;
  padding: 87px 0 24px 36px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 40px;

  &__image {
    position: absolute;
    bottom: 0;
    right: 17px;
    height: 100%;
  }

  &__texts-wrapper {
    display: flex;
    flex-direction: column;
    line-height: 1.2;
    color: $text-color;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }

  &__subtitle {
    margin-top: 4px;
    font-size: 14px;
    opacity: 0.8;
  }
}

// Mobile
.header-banner-widget {
  &.mobile {
    background-image: url('../../../../public/images/howToPlayPage/bg-shadow-mobile.png');
    // padding: 29px 0 24px 0px;
    width: 100%;
    height: auto;
    align-items: center;
    background-size: contain;
    @media (orientation: portrait) {
      width: 100%;
      height: 81px;
      background-size: cover;
      padding: unset;
    }
    @media (orientation: landscape) {
      background-image: url('../../../../public/images/howToPlayPage/bg-shadow.png');
      height: 170px;
      padding: unset;
    }

    .header-banner-widget__image {
      left: 50%;
      transform: translateX(-50%);
      height: 90px;
    }

    .header-banner-widget__title {
      text-align: center;
    }

    .header-banner-widget__subtitle {
      text-align: center;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      opacity: 0.8;
    }
  }
}
