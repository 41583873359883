@import '../../../styles/global/variables.scss';

// desktop
.tournament-types-widget-wrapper {
    &.desktop {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;

        .tournament-types-widget-container {
            width: 465px;
            height: 155px;
            background: $card-color;
            border-radius: 8px;
            padding: 20px;
            margin-bottom: 22px;
            overflow: auto;

            &:last-child {
                margin-bottom: 0px;
            }

            &__header {
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                color: $text-color;
                margin-bottom: 8px;

                &--title {
                    position: relative;

                    .title-comming-soon {
                        position: absolute;
                        right: -85px;
                        top: -5px;
                    }
                }
            }

            &__body {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: $text-color;
            }
        }
    }
}


// mobile
.tournament-types-widget-wrapper {
    &.mobile {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .tournament-types-widget-container {
            width: 100%;
            border-radius: 8px;
            overflow: hidden;
            position: relative;
            margin-bottom: 8px;
            background: $card-color;

            &__header {
                width: 100%;
                height: 43px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 12px;
                cursor: pointer;
                border: 1px solid $active-bg;
                transition: border-radius .2s;

                &.show {
                    border-radius: 8px 8px 0px 0px;
                    border-bottom: none;
                }

                &.hide {
                    border-radius: 8px;
                }

                &--title {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                    color: $text-color;
                    text-transform: capitalize;
                }

                &--icon {
                    font-size: 10px;
                    transform: rotate(90deg);

                    &.up {
                        transform: rotate(-90deg);
                    }
                }
            }

            &__body {
                width: 100%;
                max-height: 200px;
                transition: height .2s;
                border-radius: 0px 0px 8px 8px;
                padding: 0px 12px;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: $text-color;

                &.show {
                    height: 110px;
                    border: 1px solid $active-bg;
                    border-top: none;
                }

                &.hide {
                    height: 0px;
                }
            }
        }
    }
}