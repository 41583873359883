.transaction-history-filters {
  &__dropdown-wrapper {
    width: 290px;
  }
  &__presets-wrapper {
    margin-top: 20px;
  }
}

.mobile {
  .transaction-history-filters {
    &__dropdown-wrapper {
      width: 100%;
    }
    &__presets-wrapper {
      margin-top: 10px;
    }
  }
}