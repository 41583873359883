.rules {
    background-color: #F5F5F5;
    
    &__body {
        padding: 15px 15px 40px;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);

        &__items {
            margin-bottom: 30px;
        }
    }

    &.page-wrapper {
        h1 {
            margin: 20px 0px;
        }
    }
}