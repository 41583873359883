@import '../../styles/global/variables.scss';

.home-page {
  width: 100%;
  height: calc(100vh - 126px);
  min-height: 620px;
  background-color: #1B2B55;
  background-image: url('./img/welcome.png');
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  &__left-side {
    background-color: transparent;
    // background-image: linear-gradient(90deg, #FFFFFF66 44%, #FFFFFF00 52%);
    opacity: 0.95;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    padding: 10% 0px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    &-content {
      width: 70%;
    }
  }

  &__greeting-content {
    width: 50%;
    min-height: 200px;
    color: $welcome-color;

    &--title {
      font-size: 55px;
      font-weight: 800;
      text-transform: uppercase;
      margin-bottom: 20px;
      // opacity: 0.70;
      color: white;
    }

    &--text {
      font-size: 22px;
      margin-bottom: 20px;
      color: rgba(255, 255, 255, 0.80);
      // opacity: 0.70;
    }

    &--sign-in-button {
      width: fit-content;
      padding: 15px;
      font-size: 17px;
      font-weight: 700;
      color: $welcome-btn-color;
      border: 1px solid $welcome-btn-border-color;
      text-transform: uppercase;
      border-radius: 4px;
      background: linear-gradient(229deg, #B4D420 0%, #DDE065 100%);

      cursor: pointer;
      &:hover {
        border: 1px solid $welcome-btn-border-color-hover;
        background: darken($main-color, 20%);
        color: $welcome-btn-color-hover;
      }
      &:active {
        border: 1px solid $welcome-btn-border-color-active;
        background: $welcome-btn-background-active;
        color: $welcome-btn-color-active;
      }
    }
  }
  &__info-panel {
    position: absolute;
    bottom: 40px;
    width: 70%;
    left: 50%;
    transform: translateX(-50%);
  }
  &__info-panel-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
  &__info-panel-item {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(40, 58, 111, 0.40);
    border-radius: 16px;
    width: 384px;
    height: 100px;
    gap: 8px

  }
  &__info-panel-item-image {
    height: 78px;
    width: 108px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    &--1 {
      background-image: url('./img/info-panel/1.png');
    }
    &--2 {
      background-image: url('./img/info-panel/2.png');
    }
    &--3 {
      background-image: url('./img/info-panel/3.png');
    }
  }
  &__info-panel-item-texts {
    display: flex;
    flex-direction: column;
    gap: 2px;
    color: #000000;
  }
  &__info-panel-item-title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #CFDC4E;
  }
  &__info-panel-item-description {
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    margin-right: 16px;
    // opacity: 0.8;
    color: $main-text-color;
  }
}

// mobile

.mobile .home-page {
  background-image: none;
  height: auto;
  min-height: unset;
  &__left-side {
    background: none;
    padding: 0;
    background-image: url('./img/entrata-betpassionfun-mobile-2.webp');
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: calc(100vh - 80px);
  }
  &__left-side-content {
    padding: 40px 5px 0px 20px;
    width: 100%;
  }

  &__greeting-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    &--title {
      font-size: 32px;
      text-align: center;
      opacity: 0.9;
    }
    &--text {
      font-size: 20px;
      opacity: 0.9;
    }
    &--sign-in-button {
      padding: 11px;
      position: absolute;
      top: 45%;
    }
  }

  &__info-panel {
    background-color: #F0F0F0;
    position: unset;
    bottom: unset;
    width: unset;
  }
  &__info-panel-list {
    flex-direction: column;
  }
  &__info-panel-item {
    width: 100%;

  }
  &__info-panel-item-image {
    height: 72px;
    width: 165px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    &--1 {
      background-image: url('./img/info-panel/1.png');
    }
    &--2 {
      background-image: url('./img/info-panel/2.png');
    }
    &--3 {
      background-image: url('./img/info-panel/3.png');
    }
  }
  &__info-panel-item-title {
    opacity: 1;
  }
  &__info-panel-item-description {
    font-size: 24px;
    line-height: 29px;
    opacity: 1;
  }
}
