@import '../../styles/global/variables.scss';

.my-account-wrapper {
  flex: 1 0 auto;
  background: $primary-gradient;
  padding: 43px 58px 43px 58px;
  color: $light;
}

.my-account {
  width: 100%;
  display: flex;
  padding: 40px;
  background-color: #E5E5E5;
  &__view {
    background-color: #1B1D24;
    width: calc(100% - 338px);
    padding: 40px;
    border-radius: 0 8px 8px 0;
    height: 815px;
  }
  &__player {
    width: 338px;
    height: 815px;
    background-color: #1F222D;
    border-radius: 8px 0px 0px 8px;
    padding: 36px 17px 0;
    &-info-wrapper {
      .account-details__section-input-wrapper {
        width: 100%;
      }
    }
  }
  &__player-name {
    display: block;
    color: #FFF;
    font-size: 24px;
    font-weight: 700;
  }

  &__player-info {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }

  &__player-id{
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }
  &__player-balance{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #313341;
    height: 43px;
    padding: 0 12px;
    margin-left: auto;
  }
  &__player-balance-label{
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.035px;
    text-transform: capitalize;
  }
  &__player-balance-value-wrapper{
    margin-left: 8px;
  }
  &__nav-list {
    margin-top: 23px;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    border-top: 1px solid #313341;
  }
  &__nav-item {
    padding: 0 24px;
    height: 64px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    &--active, &:hover {
      background-color: #1B1D24;
      .my-account__nav-item-icon-wrapper {
        background: transparent;
      }
      .my-account__nav-item-text {
        color: #F0F0E6;
        font-size: 16px;
        font-weight: 600;
      }
    }
    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }
    & + .my-account__nav-item {
      margin-top: 20px;
    }
  }
  &__nav-item-icon-wrapper {
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1B1D24;
    border-radius: 5px;
  }
  &__nav-item-icon {
    color: #AED216;
    font-size: 16px;
  }
  &__nav-item-text {
    color: #F0F0E6;
    font-size: 16px;
    font-weight: 600;
    margin-left: 18px;
  }
  &__logout-wrapper {
    margin-top: 80px;
    border-top: 1px solid #313341;
    padding-top: 34px;
  }
}
