.app-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  min-width: 1240px;
  &.tipster {
    min-height: unset;
    background: #14161C;
  }
}

.app__main-content {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 66px);
  margin-top: 66px;
}
.app-wrapper.mobile {
  min-width: unset;
  .app__main-content {
    margin-top: 55px;
    height: calc(100vh - 55px);
    position: relative;

    @media (orientation: landscape) {
      margin-top: 55px;
      height: calc(100vh - 55px);
    }
  }
}
