@import '../../../styles/global/variables.scss';

.dropdown {
  position: relative;
  .drop-item {
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: capitalize;
  }
  &.open {
    .header {
      color: #FFFFFF;
      i {
        transform: rotate(-90deg);
        color: #FFFFFF;
      }
    }
    .body {
      max-height: 278px;
      border: 1px solid #313341;
      .drop-item {
        padding: 14px;
        &:hover {
          background-color: #282C3B;
          border-radius: 0;
          color: #ffffff;
        }
      }
    }
  }
  .header {
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 0 12px;
    height: 44px;
    border: 1px solid #313341;
    background: #1F222D;
    color: #EFEDF7;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    .drop-item {
      margin: 0;
    }
    i {
      margin-left: auto;
      color: #EFEDF7;
      transition: color 0.35s ease-in-out;
      transform: rotate(90deg);
      font-size: 8px
    }
  }
  .body {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #1F222D;
    border-radius: 8px;
    color: #EFEDF7;
    font-size: 14px;
    font-weight: 600;
    z-index: 2;
    max-height: 0;
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #ffffff;
      border-radius: 10px;
    }
  
    &::-webkit-scrollbar-thumb {
      background: #009688;
      border-radius: 10px;
    }
    .drop-item {
      margin-left: 0;
      padding: 0;
      cursor: pointer;
    }
    > div {
      & + div {
        border-top: 1px solid #313341;
      }
    }
  }
}
