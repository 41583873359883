.rotate_loader {
  width: 100vw;
  height: 100vh;
  top: 0;
  background: #000;
  z-index: 10000;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;

  &--img-wrapper {
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 170px;
    height: 170px;
    border-radius: 50%;
  }

  &--img {
    width: 120px;
    height: 120px;
    position: relative;
    animation-name: rotateAnimation;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes rotateAnimation {
    0%   {transform: rotate(-135deg); opacity: 1}
    50% {transform: rotate(-90deg); opacity: 0.5}
    100% {transform: rotate(-45deg); opacity: 0}
  }
}