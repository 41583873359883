@font-face {
  font-family: 'casino-tournament';
  src:  url('fonts/casino-tournament.eot?bsdf6x');
  src:  url('fonts/casino-tournament.eot?bsdf6x#iefix') format('embedded-opentype'),
    url('fonts/casino-tournament.ttf?bsdf6x') format('truetype'),
    url('fonts/casino-tournament.woff?bsdf6x') format('woff'),
    url('fonts/casino-tournament.svg?bsdf6x#casino-tournament') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'casino-tournament' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-info:before {
  content: "\e900";
}
.icon-user:before {
  content: "\e901";
}
.icon-filter:before {
  content: "\e902";
}
.icon-registration:before {
  content: "\e903";
}
.icon-check-circle:before {
  content: "\e904";
}
.icon-error:before {
  content: "\e905";
}
.icon-out-of-spin:before {
  content: "\e906";
}
.icon-power:before {
  content: "\e907";
}
.icon-transaction:before {
  content: "\e908";
}
.icon-arrow:before {
  content: "\e909";
}
.icon-history:before {
  content: "\e90a";
}
.icon-logout:before {
  content: "\e90b";
}
.icon-check:before {
  content: "\e90c";
}
.icon-visibility:before {
  content: "\e90d";
}
.icon-visibility-off:before {
  content: "\e90e";
}
.icon-loop:before {
  content: "\e90f";
}
.icon-arrow-right:before {
  content: "\e910";
}
.icon-sit-and-go-tournaments:before {
  content: "\e918";
}
.icon-jackpot-tournaments:before {
  content: "\e919";
}
.icon-scheduled-tournaments:before {
  content: "\e91a";
}
.icon-gift:before {
  content: "\e91b";
}
.icon-star-circle:before {
  content: "\e91c";
}
.icon-cross:before {
  content: "\e91f";
}
.icon-checkbox:before {
  content: "\e942";
}
.icon-ticket:before {
  content: "\e949";
}
.icon-winning:before {
  content: "\e955";
}
.icon-checkbox-checked:before {
  content: "\e9a1";
}
