@import '../../styles/global/variables.scss';

.userInfo {
  display: flex;
  align-items: center;

  &__warning {
    width: 26px;
    height: 26px;
  }

  &__detailed {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin-left: 20px;
    cursor: pointer;

    .icon-user {
      font-size: 20px;
      margin-right: 8px;
    }
    .icon-arrow-right {
      font-size: 8px;
      font-weight: bold;
      transform: rotate(90deg);
    }
  }

  &__button {
    height: 44px;
    background: #2e3138;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 12px;
  }

  &__balance-wrapper {
    margin-right: 12px;
    .balance__text {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      color: #fff;
      margin-right: 8px;
    }

    .fpp__img {
      width: 14px;
      height: 18px;
    }

    .fpp__amount {
      color: #efedf7;
      font-size: 14px;
    }
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

// mobile
.mobile {
  .userInfo {
    margin-right: 0px;
    .userInfo__detailed {
      margin: 0;
      .userInfo__button {
        height: 33px;
      }
      .userInfo__balance-wrapper {
        .balance__text {
          font-size: 12px;
          font-weight: 400;
          margin-right: 4px;
        }
        .fpp__img {
          width: 10px;
          height: 12px;
        }

        .fpp__amount {
          color: #efedf7;
          font-size: 12px;
          font-weight: 400;
        }
      }
      .userInfo__detailed__user {
        padding: 0px 8px;
        .icon-user {
          font-size: 14px;
          margin-right: 5px;
        }
        .icon-arrow-right {
          font-size: 6px;
          font-weight: bold;
          transform: rotate(90deg);
        }
      }
    }
  }
}
