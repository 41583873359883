@import '../../../../styles/global/variables.scss';

.steps-to-play-widget-mobile {
  min-height: 563px;
  background: $card-color;
  border-radius: 8px;
  height: 577px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;

  &__header {
    min-height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    padding-bottom: 12px;
    width: 336px;
    margin: 0 auto;

    .header-left-section {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 220px;

      &__steps-count {
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
        color: $secondary-text-color;
        text-transform: uppercase;
      }

      &__title {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: $text-color;
        margin-top: 4px;
      }
    }

    .header-right-section {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      &__button {
        width: 28px;
        height: 28px;
        background: $active-bg;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 8px;
        color: $text-color;

        &:first-child {
          transform: rotate(180deg);
          margin-right: 14px;
        }

        &.disabled {
          color: rgba(255, 255, 255, 0.1);
        }
      }
    }
  }

  &__body {
    width: 336px;
    position: relative;
    min-height: 483px;
    pointer-events: none;
    height: 505px;
    margin: 0 auto;

    &-elements {
      max-width: 100%;
      margin: 0 auto;
      display: flex;
      overflow-y: hidden;
      overflow-x: scroll;
      scroll-behavior: smooth;
      min-height: 483px;
      height: 505px;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-element {
      width: 336px;
      min-height: 483px;
      text-align: center;
      display: inline-block;
      padding: 0px 20px;
      cursor: pointer;
      list-style: none;
      height: 505px;

      .element__description-wrapper {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $text-color;
        text-align: left;

        .show-more {
          color: #0988e3;
          text-transform: capitalize;
          pointer-events: all;
        }

        .text {
          &:last-child {
            display: inline-block;
          }
        }
      }

      .element__image-wrapper {
        text-align: center;
        width: 296px;
        height: 414px;
        margin-top: 40px;
        background-position-x: center;
        background-position-y: top;
        background-size: 485px;
        background-repeat: no-repeat;

        &.img-1 {
          background-image: url('../../../../../public/images/howToPlayPage/step1-mobile.png');
        }

        &.img-2 {
          background-image: url('../../../../../public/images/howToPlayPage/step2-mobile.png');
        }

        &.img-3 {
          background-image: url('../../../../../public/images/howToPlayPage/step3-mobile.png');
        }

        &.img-4 {
          background-image: url('../../../../../public/images/howToPlayPage/step4-mobile.png');
        }
      }
    }
  }
}

.steps-to-play-widget-mobile {
  @media (orientation: landscape) {
    width: 351px;
    margin: 0 auto;
  }
}
