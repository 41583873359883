@import '../../../styles/global/variables.scss';

.presets {
  display: flex;
  align-items: center;
  gap: 10px;
  &__button-filters {
    display: flex;
    gap: 10px;
  }
  &__button-filter {
    button {
      height: 36px;
      border-radius: 4px;
      border: 1px solid #2E3138;
      background: #1F212C;

      color: #D0D0D0;
      font-size: 12px;
      font-weight: 600;
    }
    &--active {
      button {
        border: 1px solid #AED216;
      }
    }
  }
  &__range-wrapper {
    display: flex;
    gap: 10px;
    input {
      height: 36px;
      border-radius: 4px;
      border: 1px solid #2E3138;
      background: #1F212C;

      color: #D0D0D0;
      font-size: 12px;
      font-weight: 600;

      width: 130px;
      padding: 0 10px;
      &::-webkit-calendar-picker-indicator {
        cursor: pointer;
        filter: invert(1);
      }
    }
  }
  .radio-inputs-wrapper {
    display: flex;
    gap: 10px;
  }
}

// Mobile

.mobile {
  .presets {
    min-height: 55px;
    flex-direction: column;
    align-items: center;
    gap: 0;
    &__content {
      overflow-x: auto;
      display: grid;
      height: 47px;
      grid-gap: 10px;
      grid-template-columns: repeat(auto-fill,minmax(160px,1fr));
      grid-auto-flow: column;
      grid-auto-columns: minmax(160px,1fr);
      width: 100%;
      padding-bottom: 10px;
    }
  }
}

