@import '../../styles/global/variables.scss';

.fpp {
  display: flex;
  align-items: center;
  gap: 6px;
  &__img{
    width: 20px;
    height: 20px;
  }
  &__amount{
    font-weight: 700;
    font-size: 22px;
    color: $fpp-amount-color;
  }
  &--sm {
    gap: 4px;
    .fpp__img{
      width: 14px;
      height: 14px;
    }
    .fpp__amount{
      font-size: 16px;
    }
  }
}