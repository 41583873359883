@import '../../styles/global/variables.scss';

.footer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: not-allowed;
  z-index: 999;
}
.footer-wrapper {
  position: relative;
  background-color: $footer-background;
  &--fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
  }
  &--fixed-active {
    transition: 0.20s ease-in-out;
  }
  .footer-toggle {
    position: absolute;
    width: 60px;
    height: 24px;
    margin: 0;
    top: -24px;
    left: calc((100% - 60px) / 2);
    border-radius: 3px 3px 0 0;
    transition: opacity 300ms,transform 300ms;
    background-color: $secondary-btn-color-2;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i {
      transform: rotate(-90deg);
      color: $light;
      &.active {
        transform: rotate(90deg);
      }
    }
  }

  .footer-logo {
    width: auto;
    display: flex;
    align-items: center;
    img {
      width: auto;
      height: 30px;
    }
  }

  .footer__col-title {
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 16px;
    font-style: normal;
    line-height: 48px;
  }

  .footer__col-body {
    span {
      display: block;
      text-decoration: underline;
      cursor: pointer;
      font-weight: 500;
      font-size: 18px;
    }
    span,
    a {
      &:not(:last-child) {
        display: block;
        margin-bottom: 10px;
      }
    }
  }

  .footer__col-input {
    text-transform: initial;
    position: relative;
    width: fit-content;
    overflow: hidden;
    input {
      border: 1px solid black;
      border-radius: 50px;
      padding: 14px 16px;
      width: 350px;
      font-size: 18px;
    }
    img {
      position: absolute;
      height: 50%;
      top: 50%;
      right: 0;
      transform: translate(-25%, -50%);
      z-index: 1;
      cursor: pointer;
    }
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0%;
      transform: translateY(-50%);
      background-color: $footer-input-after-background;
      height: 97%;
      width: 38px;
      border-radius: 0 50px 50px 0;
      cursor: pointer;
    }
  }

  .footer__col {
    text-transform: capitalize;
    &.col-1 {
      flex: 1 0 30%;
      display: flex;
      flex-direction: column;
      align-items: center;
      & > *:not(:last-child) {
        margin-bottom: 32px;
      }
    }
    &.col-2 {
      flex: 1 0 auto;
    }
    &.col-3 {
      flex: 1 0 auto;
      span {
        text-decoration: none;
      }
    }
    &.col-4 {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  .footer {
    &__social-icons {
      display: flex;
      .social-icon {
        position: relative;
        height: 44px;
        width: 44px;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 4px;
        }
        &:before {
          content: '';
          position: absolute;
          height: 44px;
          width: 44px;
        }
        &.icon-facebook:before {
          background: url('./img/icons/facebook.png');
          background-size: cover;
        }
        &.icon-instagram:before {
          background: url('./img/icons/instagram.png');
          background-size: cover;
        }
        &.icon-telegram:before {
          background: url('./img/icons/telegram.png');
          background-size: cover;
        }
        &.icon-youtube:before {
          background: url('./img/icons/youtube.png');
          background-size: cover;
        }
        &.disable {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }

    &__contact-info {
      span {
        font-weight: 500;
        font-size: 18px;
        text-transform: initial;
      }
      span:first-child {
        font-weight: 700;
        font-size: 22px;
        text-transform: unset;
        margin-right: 8px;
      }
    }

    &__body {
      display: flex;
      padding: 90px 0;
    }

    &__footer {
      display: flex;
      align-items: center;
      background-color: $footer-copyright-background;
      height: 60px;
      width: 70%;
      position: relative;
      margin: 0 auto;

      &-copyright {
        color: $footer-copyright-color;
        font-size: 18px;
        font-weight: 700;
        width: 100%;
        text-align: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &__git-version {
      font-size: 16px;
      color: $footer-version-color;
      position: absolute;
      right: 0px;
      white-space: nowrap;
    }
  }
}

.footer__col--middle {
  display: contents;
}

.mobile {
  .footer-wrapper {
    height: 40px;
  }
  .footer__col--middle {
    display: flex;
  }

  .footer {
    &__body {
      flex-direction: column;
      border-top: 3px solid $main-color;
      padding: 40px 25px;
    }

    &__social-icons {
      .social-icon {
        height: 60px;
        width: 60px;

        &:not(:last-child) {
          margin-right: 10px;
        }

        &:before {
          height: 60px;
          width: 60px;
        }
      }
    }
    &__footer {
      flex-direction: column;
      gap: 10px;
      height: auto;
      padding: 10px 0;
    }
    &__git-version {
      position: static;
      padding-right: 0;
      margin-left: 0;
    }
  }

  .footer__col {
    &:not(:last-child) {
      margin-right: 0px;
    }

    margin-bottom: 40px;
  }

  .col-4 {
    margin-bottom: 0;
    width: 100%;

    .footer__col-title {
      text-align: center;
    }
  }

  .footer__col-input {
    width: 100%;
    input {
      width: 100%;
      font-style: normal;
      font-weight: 300;    
      color: #000000;
    }
  }
}
