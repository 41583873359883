@import '../../styles/global/variables.scss';

.auth {
  padding: 15px 22px;
  &__sections {
    width: 799px;
    height: 563px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #313341;
    background: #171922;
  }
  &__form-section {
    position: relative;
    background: #171922;
  }
  &__form-title {
    display: block;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: $main-text-color;
    text-align: left;
  }
  &__form {
    margin-top: 28px;
  }
  &__input-wrapper {
    position: relative;
    & + .auth__input-wrapper {
      margin-top: 33px;
    }
    .password-visibility-icon {
      color: #bbbbbd;
      position: absolute;
      right: 0;
      bottom: 0;
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 20px;
      max-width: 45px;
    }
    &:not(:first-child) {
      input {
        padding-right: 45px;
      }
    }
  }
  &__forgot-password-wrapper {
    margin-top: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    &--link {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.048px;
      text-transform: capitalize;
      color: #bbbbbd;
      cursor: pointer;
    }
  }
  &__button-wrapper {
    margin-top: 54px;
  }
  &__or-wrapper {
    margin-top: 28px;
    display: flex;
    align-items: center;
    gap: 12px;
  }
  &__or-text {
    font-weight: 500;
    font-size: 14px;
    line-height: normal;
    color: #fff;
    text-transform: uppercase;
  }
  &__or-line {
    flex-grow: 1;
    height: 1px;
    background: #777;
  }
  &__google-button-wrapper {
    margin-top: 28px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__route-to-auth-wrapper {
    margin-top: 40px;
    font-weight: 400;
    font-size: 12px;
    line-height: normal;
    display: flex;
    justify-content: center;
    gap: 4px;
  }
  &__route-to-auth-label {
    color: #f0f0e6;
  }
  &__route-to-auth-value {
    color: #ffbf17;
    cursor: pointer;
  }
  &__checkboxes {
    display: flex;
    flex-direction: column;
    gap: 22px;
    margin-top: 20px;
  }
  &__checkbox {
    gap: 4px;
    margin-top: 25px;
    .checkbox__input {
      height: 16px;
      width: 16px;
      border: 1px solid #bbbbbd;
      background: transparent;

      &:checked:before,
      &:checked:after {
        content: '';
        position: absolute;
        height: 1px;
        background: #bbbbbd;
      }

      &:checked:before {
        width: 4px;
        top: 8px;
        left: 2px;
        transform: rotate(44deg);
      }

      &:checked:after {
        width: 8px;
        top: 7px;
        left: 4px;
        transform: rotate(-44deg);
      }
    }
    .checkbox__label {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #bbbbbd;
      text-transform: capitalize;
    }
  }
  &__close-icon {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    right: 0px;
    background: #313341;
    color: #f0f0e6;
    font-size: 12px;
    line-height: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.app-wrapper:not(.app-wrapper.mobile) {
  .auth {
    background: url('./img/background.jpg') no-repeat center/cover;
    height: 860px;
    display: flex;
    align-items: center;
    justify-content: center;
    &__form-section {
      display: flex;
      width: 387px;
      height: 539px;
      padding: 16px 0px 0px 24px;
      flex-direction: column;
      align-items: flex-start;
    }
    &__form-section-content {
      width: 100%;
    }
    &__info-section {
      width: 388px;
      height: 539px;
      border-radius: 10px;
      background-color: #1b1e23;
      background-repeat: no-repeat;
      background-image: url('./img/auth-background-desktop.png');
    }
    &__form-title,
    &__or-wrapper,
    &__google-button-wrapper,
    &__form {
      padding-right: 12px;
    }
    &--register {
      .auth__form {
        height: 440px;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
          border-radius: 10px;
          height: 2px;
          width: 2px;
        }

        &::-webkit-scrollbar-thumb {
          background: #bdbdbd;
          border-radius: 10px;

          &:hover {
            background: #bbbbbb;
          }
        }

        &::-webkit-scrollbar-track {
          border-radius: 4px;
        }
      }
    }
  }
}

//Mobile
.app-wrapper {
  &.mobile {
    .auth {
      padding: 0px;
      &__sections {
        width: 100%;
        height: 100%;
        height: auto;
        flex-direction: column;
        padding: 0;
        border-radius: 0;
        position: relative;
      }
      &__info-section {
        height: 198px;
        width: 100%;
        background-image: url('./img/auth-background-mobile.png');
        background-size: 100% 198px;
        background-repeat: no-repeat;
        background-position: top;
      }
      &__form-section {
        position: relative;
        top: -16px;
        left: 0;
        width: 100%;
        display: flex;
        padding: 16px 20px;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 8px 8px 0px 0px;
        border: 1px solid #313341;
        border-bottom: none;
      }
      &__form-title {
        font-size: 16px;
        text-align: center;
      }
      &__or-wrapper {
        margin-top: 20px;
      }
      .auth__form-section-content {
        width: 100%;
        .input-wrapper {
          &.input-wrapper--auth {
            input {
              width: 100%;
            }
          }
        }
      }
      &__close-icon {
        top: 12px;
        right: 12px;
      }
    }
  }
}
