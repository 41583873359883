@import '../../../styles/global/variables.scss';

.button {
  border-radius: 20px;
  text-align: center;
  min-width: 130px;
  height: 40px;
  width: auto;
  text-transform: capitalize;
  cursor: pointer;
  &--sm {
    padding: 0 5px;
    font-size: 20px;
  }

  &--md {
    padding: 5px 10px;
    font-size: 18px;
  }

  &--primary, &--primary-rounded {
    border: 2px solid $primary-btn-border-color;
    background: $primary-btn-background;    
    color: $primary-btn-color;
    height: 50px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0 16px;
    font-size: 14px;
    line-height: 22px;
    transition: box-shadow 0.3s ease;

    &:hover {
      border-color: $primary-btn-border-color-hover;
      background: $primary-btn-background-hover;
      color: $primary-btn-color-hover;
    }
    &:active {
      border: 1px solid $primary-btn-border-color-active;
      background: $primary-btn-background-active;
      color: $primary-btn-color-active;
    }
  }
  &--primary {
    border-radius: 10px;
    height: 50px;
  }
  &--primary-rounded {
    border-radius: 52px;
    height: 40px;
  }
  &--secondary, &--secondary-rounded {
    color: $secondary-btn-color;
    background: $secondary-btn-background;
    border: 2px solid $secondary-btn-border-color;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    text-transform: uppercase;
    padding: 0 16px;
    &:hover {
      background: $secondary-btn-background-hover !important;
      color: $secondary-btn-color-hover;
      border-color: $secondary-btn-border-color-hover;
    }
    &:active {
      background: $secondary-btn-background-active !important;
      color: $secondary-btn-color-active;
      border: 1px solid $secondary-btn-border-color-active;
    }
  }
  &--secondary-rounded {
    border-radius: 52px;
    height: 40px;
  }
  &--secondary {
    border-radius: 10px;
    height: 50px;
  }
  &--tertiary {
    background: transparent;
    border: 1px solid $secondary-btn-color-2;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    &:hover {
      background: $secondary-btn-color-2 !important;
      color: $light;
    }
    &:active {
      background: darken($secondary-btn-color-2, 10%) !important;
      color: $light;
      border: 1px solid darken($secondary-btn-color-2, 10%);
    }
  }
  &--auth,
  &--auth-rounded {
    border-radius: 4px;
    background: linear-gradient(229deg, #b4d420 0%, #dde065 100%);
    color: #171922;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.175px;
    text-transform: uppercase;
    transition: box-shadow 0.3s ease;
    height: 44px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    &:hover {
      background: linear-gradient(229deg, #b7df05 0%, #ccd00a 100%);
    }
    &:active {
      background: linear-gradient(229deg, #b7df05 0%, #ccd00a 100%);
    }
  }
  &--logout-mobile {
    background: #1B1D24;
    height: 64px;
    color: $main-text-color;
    border-radius: 7px;
    border: 1px solid rgba(99, 99, 99, 0.5);
    display: flex;
    align-items: center;
    padding-left: 27px;
    gap: 20px;
    width: 100%;
    .button__icon {
      font-size: 18px;
      color: #D81316;
    }
    .button__text {
      font-weight: 700;
      font-size: 16px;
    }
    &:hover {
      background:  rgba(99, 99, 99, 0.5);
      color: $light;
      border: 1px solid rgba(99, 99, 99, 0.5);
    }
    &:active {
      background:  darken(rgba(99, 99, 99, 0.5), 10%);
      color: $light;
      border: 1px solid darken(rgba(99, 99, 99, 0.5), 10%);
    }
  }

  &--logout-desktop {
    background: transparent;
    border-radius: 11px;
    padding-left: 18px;
    display: flex;
    align-items: center;
    .button__icon {
      width: 34px;
      height: 34px;
      background-color: #1B1D24;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      font-size: 18px;
      color: #D81316;
    }
    .button__text {
      margin-left: 22px;
      color: #FFF;
      font-size: 16px;
      font-weight: 600;
    }
  }
  &--error {
    color: $light;
    background: $error;
    transition: box-shadow 0.3s ease;

    &:hover {
      background: darken($error, 5%);
    }
    &:active {
      background: darken($error, 10%);
    }
  }
  &.disabled,
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
