@import '../../styles/global/variables.scss';
@import '../../styles/global/mixins.scss';

.how-to-play-page {
  width: 100%;
  height: calc(100vh - 126px);
  background: $main-bg;
  overflow-y: auto;
  padding: 0px 20px;

  @include custom-scroll-bar;
  &--portrait,
  &--landscape {
    padding: 0 15px;
  }
}

.how-to-play-page-container {
  color: white;
  width: 955px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0px auto;
  padding-bottom: 100px;
  position: relative;

  .back-to-tournament-button {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $text-color;
    cursor: pointer;
    position: absolute;
    top: 12px;
    width: 160px;
    left: -203px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-transform: capitalize;

    &__icon {
      transform: rotate(180deg);
      margin-right: 8px;
      font-size: 11px;
    }

    &:hover {
      color: $secondary-text-color;
    }
  }

  .how-to-play-page {
    &__title {
      font-weight: 700;
      font-size: 28px;
      line-height: 34px;
      color: $text-color;
      text-transform: capitalize;
      margin-top: 60px;
    }

    &__greeting-text {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $text-color;
      margin-top: 20px;
      text-align: left;
    }
  }

  .tournaments-category-section {
    margin-top: 75px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .tournament-types-section {
    margin-top: 67px;
    width: 100%;

    &__title {
      font-weight: 700;
      font-size: 28px;
      line-height: 34px;
      color: $text-color;
      text-transform: capitalize;
      margin-bottom: 28px;
    }
  }

  .youtube-section__title {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: $text-color;
    text-transform: capitalize;
    margin: 60px 0px 28px;
  }

  .steps-to-play-section {
    width: 100%;
    margin-top: 60px;

    &__title {
      font-weight: 700;
      font-size: 28px;
      line-height: 34px;
      color: $text-color;
    }
  }

  .faq-section {
    width: 100%;
    margin-top: 60px;
    text-align: center;

    &__title {
      font-weight: 700;
      font-size: 28px;
      line-height: 34px;
      color: $text-color;
    }
  }
}

// mobile
.mobile {
  .how-to-play-page {
    height: calc(100vh - 120px);
    padding: 0 15px;
  }
}
.how-to-play-page-container {
  &.mobile {
    width: 100%;

    .back-to-tournament-button {
      top: 11px;
      left: 0;
      font-size: 12px;
      line-height: 15px;
      justify-content: flex-start;

      &__icon {
        font-size: 9px;
      }
    }

    .how-to-play-page {
      &__title {
        font-size: 24px;
        line-height: 29px;
        margin-top: 20px;
        color: $text-color;
        text-align: left;
      }

      &__greeting-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        margin-top: 12px;
        color: $text-color;
        overflow: hidden;

        .show-more {
          color: #0988e3;
          text-transform: capitalize;
          margin-left: 10px;
        }
      }
    }

    .tournaments-category-section {
      margin-top: 40px;

      @media (orientation: portrait) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 24px;
      }
    }

    .tournament-types-section {
      &__title {
        margin-bottom: 12px;
        font-size: 24px;
        line-height: 29px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }

    .youtube-section__title {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: $text-color;
      text-transform: capitalize;
      margin: 60px 0px 12px;
    }

    .steps-to-play-section {
      &__title {
        font-size: 24px;
        line-height: 29px;

        .steps-count {
          color: $secondary-text-color;
        }
      }
    }
  }
}

.how-to-play-page-container {
  h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.3em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 0.5em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  strong {
    font-weight: bold;
  }
}

// experiments
.header_banner_image {
  margin-top: 40px;
  img {
    border-radius: 8px;
  }
}
