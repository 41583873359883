@import '../../../styles/global/variables.scss';

.coming-soon {
    background: #3986FA;
    border-radius: 13px;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: $text-color;
    padding: 2px 8px;
    width: 85px;
    text-align: center;
}