@import '../../../../styles/global/variables.scss';

.steps-to-play-widget-desktop {
    width: 100%;
    padding: 20px;
    padding-left: 30px;
    height: 330px;
    background: $card-color;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0px;
    }

    &__left-side,
    &__right-side {
        width: 433px;
    }

    .left-side {
        &__step-count {
            font-weight: 700;
            font-size: 10px;
            line-height: 12px;
            text-transform: uppercase;
            color: $secondary-text-color;
        }

        &__title {
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            color: $text-color;
            margin-top: 4px;
        }

        &__description {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: $text-color;
            margin-top: 14px;
        }
    }

    .right-side {
        img {
            height: 100%;
            width: 100%;
        }
    }
}