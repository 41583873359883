@import '../../../styles/global/variables.scss';

.header__navigation {
  list-style: none;
  overflow-x: auto;
  padding: 0;
  margin: 0;
  min-width: 130px;
  
  &.mobile {
    height: 100vh;
  }

  display: grid;
  grid-template-columns: repeat(auto-fit, 1fr);
  gap: 60px;
  grid-auto-flow: column;
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 20px;
    height: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $secondary-btn-color;
    border-radius: 10px;
  }

  &-item {
    text-transform: capitalize;
    font-weight: 700;
    font-size: 18px;
    transition: 0.5s ease-in-out;
    text-align: center;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid transparent;

    &--active {
      border-bottom: 2px solid #AED216;
    }

    &--active,
    &:hover {
      a {
        color: #AED216;
      }
    }

    &--language {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}

/* MOBILE */

.mobile {
  .header__navigation {
    display: flex;
    flex-direction: column;
  }

  .header__navigation-item {
    &--active,
    &:hover {
      a {
        color: $mobile-nav-item-active;
      }
    }
  }
}
