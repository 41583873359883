@import '../../styles/global/variables.scss';

.header-wrapper {
  background-color: $header-background;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  min-height: 66px;
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  z-index: 999;
  border-bottom: 1px solid #313341;

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1250px;
    width: 100%;
    height: 66px;
    padding-right: 8px;
    position: relative;
  }

  .header__navigation {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .header {
    &__logo {
      flex-shrink: 0;
      .logo-wrapper {
        cursor: pointer;
      }
      .logo {
        img {
          height: 35px;
          width: auto;
        }
      }
    }

    &__login {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .button {
        height: 44px;
      }

      .button:not(:last-child) {
        border-radius: 4px;
        margin-right: 20px;
      }

      .button:last-of-type {
        border-radius: 4px;
      }
    }
    &__flag {
      margin-left: 18px;
    }
  }

  @media screen and (max-width: 1520px) {
    padding: 10px;

    .header__logo {
      margin-right: 0;
    }
  }
}

.mobile {
  .header-wrapper .header__logo .logo-wrapper {
    position: relative;
    height: 100%;
    .header__mobile-flag-wrapper {
     position: absolute;
     right: 10px;
     top: 50%;
     transform: translateY(-50%);
     height: 100%;
     width: 35px;
     display: flex;
     align-items: center;
    }
    .logo {
      height: 100%;
      display: flex;
      align-items: center;
      img {
        width: 80px;
        height: auto;
      }
    }
  }
  .header {
    &-wrapper {
      min-height: 55px;
      height: 55px;
      padding: 0px 12px 0px 16px;
      min-width: 100vw;
      width: 100vw;


      .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 100%;
        width: 100%;
        height: 55px;
        padding-right: 0px;
        position: unset;
      }

      .header__navigation {
        position: relative;
        left: unset;
        transform: unset;
      }
    }

    &__logo {
      flex: 1 0;
      height: 100%;
    }

    &__login {
      width: auto;
      .button {
        height: 33px;
        min-width: 54px;
        padding: 0px 8px;
        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }

    &__icon-user {
      color: $main-color;
      font-size: 26px;
      padding: 7px;
    }
    @media (orientation: landscape) and (min-width: 580px) {
      &__logo {
        max-width: 170px;
      }
      &-wrapper {
        min-height: 55px;
        height: 55px;
      }
      &__icon-user {
        font-size: 16px;
      }
    }
  }
}
