$primary: #165248;
$secondary: #1C4544;
$light: #FFFFFF;
$light-2: #F9FCF8;
$dark: #26242f;
$error: #FF3D00;
$main-color: #b9d342;
$border-color-dark: #066E19;
$dropdown-Item-background: #F6F6F6;
$button-shadow-color: rgba(0,0,0,0.5);
$main-color-2: #78be4b;

$secondary-btn-color-2: lighten(desaturate(adjust-hue($main-color, 3), 10.91), 5.29);
$main-text-color: #ffffff;

$primary-gradient: linear-gradient(180deg, #54B03A 0%, #328F1F 100%);
$error-gradient: linear-gradient(180deg, $error 0%, darken($error, 10%) 100%);

$light-gradient-1: linear-gradient(180deg, #FDFDFD 0%, #DFDCDC 100%);
$light-gradient-2: linear-gradient(180deg, #FEFEFE 0%, #DCD9D9 48.96%);
$light-gradient-3: linear-gradient(270deg, rgba(249, 249, 248, 0.02) 7.81%, #F9F9F8 50.52%, #E4E2E2 82.92%);
$light-gradient-4: linear-gradient(180deg, #FFFFFF 0%, #D7D3D3 100%);

/* ==================== Buttons Start ==================== */

$primary-btn-border-color: $main-color;
$primary-btn-background: $main-color;
$primary-btn-color: $dark;

$primary-btn-border-color-hover: $main-color;
$primary-btn-background-hover:transparent;
$primary-btn-color-hover: $light;

$primary-btn-border-color-active: darken($main-color, 10%);
$primary-btn-background-active: darken($main-color, 10%);
$primary-btn-color-active: rgba($dark, 0.8);

$secondary-btn-border-color: $main-color;
$secondary-btn-background: transparent;
$secondary-btn-color: $light;

$secondary-btn-border-color-hover: transparent;
$secondary-btn-background-hover: $main-color;
$secondary-btn-color-hover: $dark;

$secondary-btn-border-color-active: darken($main-color, 10%);
$secondary-btn-background-active: darken($main-color, 10%);
$secondary-btn-color-active: $dark;

/* ==================== Buttons End ==================== */

/* ==================== Header Start ==================== */

$header-background: #001DBE;
$header-underliner: $main-color-2;

/* ==================== Header End ==================== */

/* ==================== Footer Start ==================== */

$footer-background: #26242f;
$footer-copyright-background: #26242f;

$footer-copyright-color: $light;
$footer-version-color: $light;

$footer-input-after-background: $main-color-2;

/* ==================== Footer End ==================== */

/* ==================== Auth Start ==================== */

$auth-background: $dark;
$auth-info-background: $main-color-2;
$auth-info-button-hover: $main-color;

/* ==================== Auth End ==================== */

/* ==================== Language Start ==================== */

$language-background: #3e3c44;
$language-item-border: $main-color-2;
$language-item-color: $light;

$language-item-background-hover: $main-color;
$language-item-color-hover: $dark;

/* ==================== Language End ==================== */

/* ==================== Welcome Start ==================== */

$welcome-color: $dark;

$welcome-btn-border-color: $main-color;
$welcome-btn-background: $main-color;
$welcome-btn-color: $dark;

$welcome-btn-border-color-hover: $main-color;
$welcome-btn-background-hover:transparent;
$welcome-btn-color-hover: $dark;

$welcome-btn-border-color-active: darken($secondary-btn-color-2, 10%);
$welcome-btn-background-active: darken($secondary-btn-color-2, 10%);
$welcome-btn-color-active: rgba($light, 0.8);

/* ==================== Welcome End ==================== */

/* ==================== Mobile Menu Burger Start ==================== */

$menu-burder-background: $dark;

/* ==================== Mobile Menu Burger End ==================== */

/* ==================== Desktop Navigation Start ==================== */

$nav-item-active: $main-color-2;

/* ==================== Desktop Navigation End ==================== */

/* ==================== Mobile Navigation Start ==================== */

$mobile-nav-item-active: $main-color-2;

/* ==================== Mobile Navigation End ==================== */

/* ==================== FPP Start ==================== */

$fpp-amount-color: $main-color-2;

/* ==================== FPP End ==================== */

/* ==================== Table Start ==================== */

$table-body-color: $light;

/* ==================== Table End ==================== */

/* ==================== Accordion Start ==================== */

$accordion-body-text-color: $dark;

/* ==================== Accordion End ==================== */

/* ==================== Mobile Account Player Info Start ==================== */

$mobile-account-player-info-background: #20232E;

/* ==================== Mobile Account Player Info End ==================== */

/* ==================== My Account View Start ==================== */

$my-account-view-background: $dark;
$my-account-background: #1F222D;

/* ==================== My Account View End ==================== */


/* ==================== How To Play ==================== */
$main-bg: #14161C;
$secondary-bg: #1B1D24;
$active-bg: #313341;
$text-color: #F0F0E6;
$card-color: #1f222d;
$secondary-text-color: #FFBF17;