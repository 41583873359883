@import '../../../styles/global/variables.scss';

.faq-widget-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    .faq-widget-container {
        width: 710px;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        margin-bottom: 16px;
        background: $secondary-bg;

        &__header {
            width: 100%;
            height: 51px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 16px 21px;
            border: 1px solid $active-bg;
            transition: border-radius .2s;

            &.show {
                border-radius: 8px 8px 0px 0px;
                border-bottom: none;
            }

            &.hide {
                border-radius: 8px;
            }

            &--title {
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                color: $text-color;
                text-align: left;
            }

            &--icon {
                font-size: 16px;
                font-weight: bold;
                width: 24px;
                height: 24px;
                background: $active-bg;
                border: 1px solid $active-bg;
                border-radius: 3px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                color: $text-color;
            }
        }

        &__body {
            width: 100%;
            max-height: 200px;
            transition: height .2s;
            border-radius: 0px 0px 8px 8px;
            padding: 0px 28px;
            text-align: left;

            &.show {
                height: 60px;
                border: 1px solid $active-bg;
                border-top: none;
            }

            &.hide {
                height: 0px;
            }

            &--item {

                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: $text-color;
            }
        }
    }
}

// mobile
.faq-widget-wrapper {
    &.mobile {
        width: 100%;

        .faq-widget-container {
            width: 100%;
            margin-bottom: 12px;

            &__header {
                padding: 12px;

                &--title {
                    font-size: 14px;
                    line-height: 17px;
                    color: $text-color;

                    @media (orientation: portrait) {
                        width: 280px;
                    }
                }
            }

            &__body {
                &.show {
                    height: 105px;

                    @media (orientation: landscape) {
                        height: 60px;
                    }
                }
            }
        }
    }
}