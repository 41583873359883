@import '../../../styles/global/index.scss';
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@800&display=swap');

.dropdown-item {
  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &:last-of-type {
    margin-bottom: 80px;
  }

  &__title {
    // background: $primary-gradient;
    background-color: #DEDEDE;
    padding: 15px;
    font-size: 20px;
    font-weight: 800;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // color: $light;
    color: #333;
    cursor: pointer;

    &:hover {
      background-color: #DEDEDE !important;
    }
  }

  &__body {
    background-color: $dropdown-Item-background;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-text {
      padding: 20px;
      font-size: 17px;
      line-height: 30px;
      color: $accordion-body-text-color;
    }
  }

  &__img {
    &.img-3 {
      height: 234px;
      width: 380px;
      background: url('../../Guide/img/guide3.png') 0 100% no-repeat;
    }

    &.img-4 {
      height: 234px;
      width: 300px;
      background: url('../../Guide/img/guide4.png') center no-repeat;
    }
  }
}

// mobile

.mobile {
  &.dropdown-item {
    .dropdown-item__body {
      flex-direction: column-reverse;
    }

    .dropdown-item__img {
      margin-top: 10px;
      width: 100%;
      height: 150px;
      background-position: center top;
      background-size: contain;
    }
  }
}

