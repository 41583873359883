@import '../../../styles/global/variables.scss';

.my-account__mobile {
  background-color: $my-account-background;
  min-height: calc(100vh - 95px);
  &-logout {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 20px;
    margin-top: 10px;
  }
  &-player-info {
    background-color: $mobile-account-player-info-background;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    justify-content: space-between;
    color: rgba($light, 0.8);
    .account-details__section-input-wrapper {
      width: 100%;
    }
  }
  &-player-info-col{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 14px;
  }
  &-player-id{
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
  }
  &-player-balance{
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &-player-balance-label{
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize;
  }
  &-player-balance-value-wrapper{
    padding: 6px 12px;
    border-radius: 24px;
    background-color: $light;
  }
  
  &-player-name {
    font-size: 24px;
    font-weight: 700;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 90%;
  }
  &-list { 
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &-item-wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &-item {
    padding: 0 27px;
    color: $main-text-color;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    border: 1px solid #313341;
    border-radius: 7px;
    background: #1B1D24;
    height: 64px;
    display: flex;

    &--active {
      background: #1B1D24;
      color: $light;
      border-color: #AED216;
      .my-account__mobile-item-icon-wrapper {
        // background-color: #EDF6EB;
      }
      .my-account__mobile-item-arrow {
        transform: rotate(90deg);
      }
    }
    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  &-item-icon-wrapper {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 5px;
  }
  &-item-icon {
    font-size: 18px;
    color: $secondary-btn-color-2;
  }
  &-item-title {
    margin-left: 19px;
  }
  &-item-arrow {
    margin-left: auto;
    font-size: 16px;
  }
}

.account-details-mobile {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .my-account__section-item {
    background: #1B1D24;
    border-radius: 7px;
    padding: 20px;
  }
  .my-account__section-name {
    font-weight: 700;
    font-size: 18px;
    line-height: 21.6px;
    text-transform: capitalize;
    color: $main-text-color;
    padding-bottom: 10px;
    border-bottom: 1px solid lighten(desaturate(adjust-hue($main-color, -2), 8.45), 5.88);
    display: block;
  }

  .my-account__section-body {
    margin-top: 22px;
    display: flex;
    flex-direction: column;
    gap: 18px;
  }
}

.mobile-transaction-history {
  background-color: $my-account-view-background;
  padding: 25px 20px;
  border-radius: 7px;
}

.user-info {
  &__circle {
    width: 16px;
      height: 16px;
      cursor: pointer;
      &--spinner {
        animation: spin 0.4s linear infinite;
        pointer-events: none;
      }
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}