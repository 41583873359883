@import '../../../styles/global/variables.scss';

.menu-burger {
  clear: both;
  transform: translate(calc(-100% - 16px));
  transition: transform 0.2s ease-out;
  background-color: $menu-burder-background;
  position: absolute;
  top: 55px;
  width: 100vw;
  height: 100vh;

  .header__navigation {
    gap: unset;
    text-align: unset;
    margin: unset;
  }

  .header__navigation-item {
    padding: 10px 14px;
    text-align: unset;
    border-bottom: 1px solid $main-color;
    a {
      display: block;
      width: 100%;
    }
  }
}

.menu-icon {
  cursor: pointer;
  display: inline-block;
  position: relative;
  user-select: none;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 33px;

  &__name {
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    color: #fff;
    text-transform: capitalize;
    bottom: -11px;
    position: relative;
  }
}

.menu-icon .navicon {
  background-color: #fff;
  display: block;
  height: 2px;
  position: relative;
  transition: background-color 0.2s ease-out;
  width: 20px;
  margin-top: 9px;
}

.menu-icon .navicon:before,
.menu-icon .navicon:after {
  background-color: #fff;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.menu-icon .navicon:before {
  top: 6px;
}

.menu-icon .navicon:after {
  top: -6px;
}

.menu-btn {
  display: none;
}

.menu-btn.active ~ .menu-burger {
  transform: translate(-16px);
}

.menu-btn.active ~ .menu-icon .navicon {
  background: transparent;
}

.menu-btn.active ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.menu-btn.active ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.menu-btn.active ~ .menu-icon:not(.steps) .navicon:before,
.menu-btn.active ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}