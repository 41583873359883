#tipsterPluginContainer {
  > iframe {
    height: 0!important;
  }
  &.tipster {
    > iframe {
      height: 100%!important;
    }
    &.mobile {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }
  }
}