.dialog-template {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  inset: 0;
  z-index: 100000000000;

  &__overlay {
    position: absolute;
    inset: 0;
    background-color: rgba(1, 1, 1, 0.5);
    z-index: 0;
    pointer-events: none;
  }

  &__inner {
    width: 497px;
    background: #1b1d24;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    padding-top: 40px;
    position: relative;
    text-align: center;

    &.mobile:not(.landscape) {
      width: 90%;
      padding-top: 36px;
      .dialog-template__body {
        margin-top: 12px;
      }
      .dialog-template__close-wrapper {
        top: 8px;
        right: 8px;
      }
      .dialog-template__title {
        font-size: 22px;
      }
      .dialog-template__text {
        margin-top: 9px;
        font-size: 16px;
        padding: 0 13px;
      }
      .dialog-template__buttons-wrapper {
        padding: 18px 20px;
        button {
          height: 40px;
        }
      }
    }
  }
  &__close-wrapper {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #313341;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  &__close {
    color: #c2c2c2;
    font-size: 10px;
  }
  &__title {
    font-weight: 700;
    font-size: 26px;
    line-height: 1.2;
    color: #ffffff;
    width: 86%;
    display: inline-block;
  }
  &__text {
    padding: 0 45px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.18;
    color: #f0f0e6;
    margin-bottom: 0;
    margin-top: 12px;
    span {
      color: #ffbf17;
      &.underline {
        text-decoration: underline;
      }
    }
  }
  &__body {
    background: #14161c;
    border-radius: 0 0 8px 8px;
    margin-top: 20px;
  }
  &__buttons-wrapper {
    padding: 24px 20px;

    button {
      height: 51px;
      width: 100%;
      border-radius: 8px;
      font-weight: 700;
      font-size: 16px;
      line-height: 1.18;
      border: none;
      outline: none;
      text-transform: uppercase;
      transition: 0.35s ease-out;
      cursor: pointer;
    }
  }
}
