@import './reset.scss';
@import './variables.scss';
@import './icons/style.scss';

html {
  font-family: 'Inter', sans-serif;
  color: $main-text-color;
}

body {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; 
  color: #ffffff!important;
}

a {
  color: $main-text-color;
}

.page-wrapper {
  // background: url('./img/shape-top.png') 0% -90px no-repeat, url('./img/shape-bottom.png') 0% 105% no-repeat;
  white-space: pre-line;
  min-height: 100vh;
  /*remove after news page is ready*/

  @media screen and (min-width: 1650px) {
    background-size: contain;
  }

  padding: 25px 110px 50px;

  &__image {
    width: 100%;
    
    img {
      margin-bottom: 40px;
      width: 100%;
    }
  }

  h1 {
    text-transform: capitalize;
    color: lighten(desaturate(adjust-hue($main-color, -2), 7.36), 6.86);
    margin-bottom: 40px;
    font-size: 30px;
    font-weight: 800;
  }

  h2 {
    color: lighten(desaturate(adjust-hue($main-color, -2), 7.36), 6.86);
    font-size: 26px;
    font-weight: 800;
    margin-top: 40px;
    line-height: 40px;
    &:first-of-type {
      margin-top: 0;
    }
  }

  h3 {
    color: lighten(desaturate(adjust-hue($main-color, -2), 7.36), 6.86);
    font-size: 20px;
    font-weight: 800;
    line-height: 30px;
  }

  &__text {
    font-size: 17px;
    margin-bottom: 20px;
    display: block;
    line-height: 30px;
    color: #333;
  }
}

.expand-icon {
  height: 12px;
  width: 24px;
  background: url(./img/arrDown.png) no-repeat;
  transition: transform 240ms;

  &.open {
    transform: rotate(180deg);
  }
}
.success-filled-btn {
  background-color: #0F5903;
  color: #F0F0E6;
  &:hover {
    background-color: #287E1B;
  }
  &:active {
    background-color: #0B4102;
  }
}

.mobile {
  .page-wrapper {
    padding: 0px;
    background: unset;
  }
}