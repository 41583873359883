@import '../../../styles/global/variables.scss';

.language-selector {
  position: relative;
  img {
    cursor: pointer;
    height: 30px;
    width: 40px;
    margin-right: 8px;
    border: 1px solid grey;
  }

  &__list {
    height: fit-content;
    position: absolute;
    top: 40px;
    left: -100%;
    transform: translateX(-25%);
    padding: 4px 0;
    border-radius: 4px;
    margin-left: -4px;
    background-color: $language-background;
  }

  &__list-row {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 4px 16px;
    color: $language-item-color;

    &:not(:last-child) {
      border-bottom: 1px solid $language-item-border;
    }

    &:hover {
      background-color: $language-item-background-hover;
      color: $language-item-color-hover
    }

    img {
      height: 20px;
      width: 30px;
    }
  }
}
