@import '../../../styles/global/variables.scss';

.prize-and-buy-in-types-widget-wrapper {
    margin-top: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    .prize-and-buy-in-types-widget__item {
        width: 465px;
        height: 260px;
        background: $card-color;
        border-radius: 8px;
        padding: 20px;
        overflow: hidden;

        .icon-row {
            width: 50px;
            height: 50px;
            background: $main-bg;
            border: 1px solid $active-bg;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                font-size: 20px;
                color: $secondary-text-color;
            }
        }

        .title-row {
            margin-top: 12px;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            color: $text-color;
        }

        .info-row {
            margin-top: 12px;
            padding-left: 25px;

            &__list {
                list-style: unset;
                margin-top: 12px;
                padding-left: 0px;

                &--item {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: $text-color;
                }
            }
        }
    }
}


//mobile
.prize-and-buy-in-types-widget-wrapper {
    &.mobile {
        .prize-and-buy-in-types-widget__item {
            width: 100%;
            height: 327px;
            margin-bottom: 26px;
            padding: 16px;

            .title-row {
                margin-top: 16px;
            }

            .info-row {
                padding-left: 20px;
            }
        }
    }
}