@import '../../../styles/global/variables.scss';

.tournament-category-widget-wrapper {
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .category-icon {
        background: $card-color;
        height: 102px;
        width: 102px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;

        i {
            color: $secondary-text-color;
            font-size: 40px;
            height: 41px;
            width: 41px;
        }
    }

    .category-description {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: $text-color;
        margin-bottom: 10px;
    }
}