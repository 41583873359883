@import '../../../styles/global/variables.scss';

.input-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  .input {
    outline: none;
    &--error {
      color: $error!important;
      &::placeholder {
        color: $error;
      }
    }
    &__error-text {
      position: absolute;
      top: 100%;
      left: 0;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: $error;
    }
  }
  &--primary {
    label {
      color: #E0E0E0;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.035px;
      text-transform: capitalize;
      position: relative;
      display: block;
      padding-left: 16px;
      &::before {
        content: '*';
        position: absolute;
        width: 8px;
        height: 12px;
        top: 0;
        left: 0;
        color: #F7706D;
        font-size: 16px;
        font-weight: 400;
      }
    }
    .input {
      margin-top: 8px;      
      border-radius: 4px;
      border: 1px solid #313341;
      background: #1F222D;
      height: 40px;
      padding-left: 12px;
      color: #E0E0E0;
      font-size: 14px;
      font-weight: 400;
      &::placeholder {
        color: #BBBBBD;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 12px;
      }
    }
  }
  &--auth {
    label {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.035px;
      margin-bottom: 8px;
    }
    .input {
      border-radius: 4px;
      border: 1px solid #313341;
      background: #1f222d;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.035px;
      color: #e0e0e0;
      display: flex;
      width: 351px;
      height: 40px;
      padding: 10px 8px;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
    }
  }
  .required {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #f7706d;
    margin-right: 4px;
  }
}

// .input:focus-visible {
//   border-color: tomato
// }
