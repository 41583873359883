@import '../../../styles/global/variables.scss';

.tableFixHead {
  overflow: auto;
  white-space: nowrap;
  max-height: 100%;
  height: 100%;
  border: 2px solid #313341;
  border-radius: 10px;
  &::-webkit-scrollbar {
      height: 0;
      width: 0;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ffffff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: green;
    border-radius: 10px;
  }
}
.tableFixHead thead th {
  position: sticky;
  top: -1px;
  z-index: 1;

  background: #1F222D;

  color: rgba(187, 187, 189, 0.90);
  font-size: 16px;
  font-weight: 500;
  height: 44px;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  overflow-y:scroll;
}
th,
td {
  padding: 6px 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
}
th {
  text-align: left;
  text-transform: capitalize;
}
tr {
  height: 50px;
  td {
    border-bottom: 2px solid #313341;
  }
}
tbody {
  color: #BBBBBD;
  font-size: 14px;
  font-weight: 500;
}
.label-sort-wrapper {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

table tr th {
  border-bottom: 2px solid #313341;
}

// Mobile

.mobile {
  .tableFixHead {
    th,
    td {
      padding: 3px 6px;
      font-size: 12px;
    }
  }
}