.transaction-history {
  height: 100%;
  &__table-wrapper {
    margin-top: 10px;
    height: calc(100% - 107px);
  }
}
.mobile {
  .transaction-history {
    &__table-wrapper {
      width: 100%;
    }
    &__button-show-more {
      display: block;
      margin: 24px auto 0;
      border-radius: 4px;
      border: 1px solid #F0F0E6;
      background: transparent;
      min-width: 123px;
      height: 32px;
      color: #F0F0E6;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.175px;
      text-transform: uppercase;
    }
  }
}