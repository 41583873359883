@import '../../../styles/global/variables.scss';

.checkbox {
  display: flex;
  align-items: center;
  gap: 12px;
  &__input {
    height: 23px;
    width: 23px;
    margin: 0;
    padding: 0;
    opacity: 1;
    appearance: none;
    border: 2px solid $secondary-btn-color-2;
    border-radius: 3px;
    background: $light;
    position: relative;
    flex-shrink: 0;
    &:checked {
      border: 2px solid $secondary-btn-color-2;
      background: $secondary-btn-color-2;
    }
    
    &:checked:before, &:checked:after {
      content: "";
      position: absolute;
      height: 2px;
      background: $light;
    }
      
    &:checked:before {
      width: 8px;
      top: 11px;
      left: 1px;
      transform: rotate(44deg);
    }
    
    &:checked:after {
      width: 15px;
      top: 8px;
      left: 5px;
      transform: rotate(-44deg);
    }
    
    &:focus {
      outline: none;
    }
  }
}
