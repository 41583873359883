@import '../../../styles/global/variables.scss';
.account-details {
  height: 100%;
  overflow: auto;
  &__sections {
    display: flex;
    flex-direction: column;
  }
  &__section {
   & + .account-details__section {
      margin-top: 73px;
   }
  }
  &__section-input-wrapper {
    width: calc(50% - 30px);
  }
  &__section-title {
    display: block;
    width: 100%;
    color: #F0F0E6;
    font-size: 20px;
    font-weight: 700;
    text-transform: capitalize;
    padding-bottom: 7px;
    border-bottom: 1px solid #AED216;
  }
  &__section-form {
    margin-top: 34px;
    display: flex;
    flex-direction: column;
  }
  &__section-form-row {
    display: flex;
    & + .account-details__section-form-row {
      margin-top: 37px;
    }
  }
  &__submit-wrapper {
    margin-top: 50px;
    width: 200px;
  }
  &__section-input-wrapper {
    & + .account-details__section-input-wrapper {
      margin-left: 28px;
    }
  }
}

.account-details::-webkit-scrollbar {
  display: none;
}

.account-details {
  -ms-overflow-style: none;
  scrollbar-width: none;
}